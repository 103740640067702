export default {
  BUTTON_PRIMARY_CLASS: 'cm_button btn',
  BUTTON_SECONDARY_CLASS: 'cm_button btn outline',

  SEARCH_BOX_PLACEHOLDER: 'Search...',

  SELECT_YOUR_VEHICLE: 'Select your Model',
  SELECT_YOUR_VEHICLE_BLOCK: 'Select your <span>Model</span>',

  GET_PRODUCTS_FOR_VEHICLE: 'Get products for your model',
  STORE_VEHICLE_IN_GARAGE: 'Store your model in the garage',

  ADD_VEHICLE: 'Add a Model',
  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',

  GARAGE_TITLE: '',

  PRODUCT_REPEATER_CLASS: 'product-loop',
};
