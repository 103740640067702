const baseFitmentFields = ['Make', 'Model', 'Year'];
const extraFitmentFields = [];

const categorySelectionPageUrl = '/collections';

const updateCallback = () => {
  window.theme.Collection(document.getElementById('itemsBlock'));
  window.CompareProducts();
};

const InitFunc = () => {
  if (window.document.querySelector('#cm_results')) {
    const unwantedPageWrappers = [['.container .nine.center.background-white', ['background-white', 'nine']]];

    unwantedPageWrappers.forEach(([elemSelector, className]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(...className);
    });
  }
};

export default {
  platform: 'shopify',
  InitFunc,
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [{ pathname: categorySelectionPageUrl, field: 'category' }],
  },
  SearchRequestDefaults: {
    pageSize: 36,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchBox',
      location: '.search.static-search:not(.mobile-search)',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#cm-mobile-search-box',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 656,
    },
    {
      name: 'Garage',
      location: {
        selector: '#cm-garage',
        class: 'cm_garage-desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        selector: '#cm-mobile-garage',
        class: 'cm_garage-mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-list',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      initCollapsed: true,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
