//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-868:_8380,_388,_2288,_6344,_9032,_7147,_9802,_4562;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-868')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-868', "_8380,_388,_2288,_6344,_9032,_7147,_9802,_4562");
        }
      }catch (ex) {
        console.error(ex);
      }